<template>
  <page-template>
    <mf-loading-dialog :loading="validatingToken">
      <v-layout align-center class="confirm-email-layout" justify-center row>
        <v-stepper v-model="step" color="primary" flat>
          <v-card class="confirm-email pa-10">
            <h3 class="primary--text mt-n3 pa-2 title text-center">Confirmação de e-mail</h3>
            <v-stepper-header class="stepper-header">
              <v-stepper-step color="#004D99" :complete="step > 1" step="1"> Confirmar e-mail </v-stepper-step>

              <v-divider :class="{ 'divider-active': step > 1 }"></v-divider>

              <v-stepper-step color="#004D99" :complete="step > 2" step="2"> Redefinir senha </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-text-field v-model="email" class="pt-1" disabled label="E-mail" outlined />
                <v-text-field
                  v-model="oldPassword"
                  :append-icon="visibilityOldPassword ? 'visibility_off' : 'visibility'"
                  autocomplete="off"
                  :error-messages="oldPasswordError"
                  label="Senha atual"
                  outlined
                  :type="visibilityOldPassword ? 'text' : 'password'"
                  @click:append="() => (visibilityOldPassword = !visibilityOldPassword)"
                  @keyup.enter="confirmEmail"
                />
                <v-layout justify-center>
                  <v-card-actions class="d-block filled pa-0">
                    <v-btn class="btn-submit-form" color="primary" :loading="loading" @click="confirmEmail"> Confirmar E-mail </v-btn>
                  </v-card-actions>
                </v-layout>
              </v-stepper-content>
              <v-stepper-content step="2">
                <password-rules :validations="v$" />
                <v-text-field
                  v-model="newPassword"
                  :append-icon="visibilityNewPassword ? 'visibility_off' : 'visibility'"
                  autocomplete="off"
                  class="pt-1"
                  :error-messages="newPasswordError"
                  label="Senha nova"
                  outlined
                  tabindex="1"
                  :type="visibilityNewPassword ? 'text' : 'password'"
                  @click:append="() => (visibilityNewPassword = !visibilityNewPassword)"
                />
                <v-text-field
                  v-model="newPasswordConfirmation"
                  :append-icon="visibilityNewPassword ? 'visibility_off' : 'visibility'"
                  autocomplete="off"
                  :error-messages="newPasswordConfirmationError"
                  hint="As senhas devem ser iguais"
                  label="Confirmação da nova senha"
                  outlined
                  tabindex="2"
                  :type="visibilityNewPassword ? 'text' : 'password'"
                  @click:append="() => (visibilityNewPassword = !visibilityNewPassword)"
                  @keyup.enter="submit"
                />
                <v-layout justify-center>
                  <v-card-actions class="d-block filled pa-0">
                    <v-btn class="btn-submit-form" color="primary" :loading="loading" @click="submit"> Redefinir senha </v-btn>
                  </v-card-actions>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-card>
        </v-stepper>
      </v-layout>
    </mf-loading-dialog>
  </page-template>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'pinia'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, sameAs } from '@vuelidate/validators'

import { useStore as mainUseStore } from '@/store'
import PageTemplate from '../components/PageTemplate.vue'
import PasswordRules from '../components/PasswordRules.vue'
import { getFingerprint } from '@/services/fingerprint.js'
import { containsUppercase, containsLowercase, containsNumber } from '@/helpers/validators'
import { QUERY_CONFIRM_PASSWORD, MUTATION_CONFIRM_EMAIL_AND_CHANGE_PASSWORD, QUERY_VALIDATE_EMAIL_CONFIRMATION_TOKEN } from '@/modules/login/graphql'

export default {
  name: 'ConfirmEmail',
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    PageTemplate,
    PasswordRules,
  },
  data: () => ({
    email: '',
    token: '',
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    validatingToken: true,
    visibilityOldPassword: false,
    visibilityNewPassword: false,
    loading: false,
    isOldPasswordWrong: false,
    step: 1,
  }),
  computed: {
    oldPasswordError() {
      if (this.isOldPasswordWrong) {
        return 'Senha incorreta.'
      }
      if (this.v$?.oldPassword?.$error) {
        if (this.v$?.oldPassword?.required) {
          return 'Campo obrigatório.'
        }
      }
      return ''
    },
    newPasswordError() {
      if (this.v$?.newPassword?.$error) {
        if (this.v$?.newPassword?.required.$invalid) {
          return 'Campo obrigatório.'
        }
        if (this.v$?.newPassword?.minLength.$invalid) {
          return 'Utilize pelo menos 10 caracteres'
        }
        if (this.v$?.newPassword?.maxLength.$invalid) {
          return 'Utilize menos de 24 caracteres'
        }
        if (this.v$?.newPassword?.containsUppercase.$invalid) {
          return 'Utilize pelo menos uma letra maiúscula'
        }
        if (this.v$?.newPassword?.containsLowercase.$invalid) {
          return 'Utilize pelo menos uma letra minúscula'
        }
        if (this.v$?.newPassword?.containsNumber.$invalid) {
          return 'Utilize pelo menos um número'
        }
      }
      return ''
    },
    newPasswordConfirmationError() {
      if (this.v$?.newPasswordConfirmation?.$error) {
        if (this.v$?.newPasswordConfirmation?.samePassword.$invalid) {
          return 'Repita a senha anterior'
        }
      }
      return ''
    },
  },
  async mounted() {
    this.getTokenFromQuery()
    await this.validateToken()
  },
  methods: {
    ...mapActions(mainUseStore, ['setRedirectUrlAfterAutentication']),
    async confirmEmail() {
      this.v$.$touch()
      if (this.v$.$error) {
        this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: 'error' })
        return
      }
      this.loading = true
      this.isOldPasswordWrong = false
      try {
        await this.$apollo.query({
          query: QUERY_CONFIRM_PASSWORD,
          variables: {
            password: this.oldPassword,
            token: this.token,
          },
        })
        this.step++
      } catch (err) {
        if (err.graphQLErrors?.[0]?.message === 'Password not match') {
          this.$snackbar({
            message: 'A senha fornecida não corresponde com a senha atual. Em caso de dúvidas entre em contato com nosso suporte.',
            snackbarColor: 'error',
          })
          this.isOldPasswordWrong = true
        } else {
          this.$snackbar({
            message: 'Ocorreu uma falha ao comunicar com o servidor. Em caso de dúvidas entre em contato com nosso suporte.',
            snackbarColor: 'error',
          })
        }
      }
      this.loading = false
    },
    async submit() {
      this.v$.$touch()
      if (this.v$.$error) {
        this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: 'error' })
        return
      }
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CONFIRM_EMAIL_AND_CHANGE_PASSWORD,
          variables: {
            email: this.email,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
        })
        Swal.fire({
          text: 'Alterações de senha e e-mail confirmadas',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            content: 'content-class',
          },
        })

        let defaultRedirect = 'https://web.mercafacil.com/#/home/'

        if (window.localStorage.getItem('redirect-url')) {
          defaultRedirect = JSON.parse(window.localStorage.getItem('redirect-url'))
        }

        this.setRedirectUrlAfterAutentication('', defaultRedirect)
        this.$router.push({ name: 'login' })
      } catch (e) {
        console.error(e)
        this.$snackbar({ message: 'Ocorreu uma falha ao comunicar com o servidor.', snackbarColor: 'error' })
        this.loading = false
      }
    },
    getTokenFromQuery() {
      this.token = this.$route.query.token

      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          token: undefined,
        },
      })
    },
    async validateToken() {
      const fingerprint = await getFingerprint()
      this.$setFingerPrint(fingerprint)

      this.validatingToken = true
      try {
        const {
          data: {
            emailConfirmationToken: { email },
          },
        } = await this.$apollo.query({
          query: QUERY_VALIDATE_EMAIL_CONFIRMATION_TOKEN,
          fetchPolicy: 'no-cache',
          variables: {
            token: this.token,
          },
        })
        this.email = email
        this.validatingToken = false
      } catch (err) {
        console.log(err)
        if (err.graphQLErrors?.[0]?.message === 'authentication.error.email.confirmation.email.mismatch') {
          this.$snackbar({ message: 'Esse e-mail de confirmação de e-mail já foi utilizado ou não é mais válido.', snackbarColor: 'error' })
        } else {
          this.$snackbar({ message: 'Não foi possível confirmar o e-mail.', snackbarColor: 'error' })
        }
        this.$router.push({ name: 'login' })
      }
    },
  },
  validations() {
    if (this.step === 1) {
      return {
        oldPassword: {
          required,
        },
      }
    } else if (this.step === 2) {
      return {
        newPassword: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(24),
          containsUppercase,
          containsLowercase,
          containsNumber,
        },
        newPasswordConfirmation: {
          samePassword: sameAs(this.newPassword),
        },
      }
    }
    return {}
  },
}
</script>

<style lang="scss">
.confirm-email-layout {
  width: 100%;
  height: 100vh;
  .confirm-email {
    width: 600px;
    border-radius: 6px;
    .card-title {
      padding: 0 0 0 20px;
      font-size: 22px;
    }
  }
}

.stepper-header {
  box-shadow: none;

  hr {
    margin: 0 !important;
  }

  .divider-active {
    border-color: #004d99 !important;
  }

  .v-stepper__step__step {
    width: 28px;
    height: 28px;
    font-size: 14px;
    background-color: transparent !important;
  }

  .v-stepper__label {
    font-weight: 400;
    line-height: 20px;
  }

  .v-stepper__step--active > .v-stepper__step__step {
    border: 1.4px solid #004d99;
    color: #004d99;
  }

  .v-stepper__step--active > .v-stepper__label {
    color: #004d99;
  }

  .v-stepper__step--complete > .v-stepper__label {
    color: #004d99 !important;
  }

  .v-stepper__step--complete > .v-stepper__step__step {
    border: 1.4px solid #004d99;
    background-color: #004d99 !important;
  }

  .v-stepper__step--inactive > .v-stepper__step__step {
    border: 1.4px solid rgba(0, 0, 0, 0.38);
    color: rgba(0, 0, 0, 0.38);
  }
}
</style>
