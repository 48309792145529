export const containsUppercase = (value) => /[A-Z]/.test(value)

export const containsLowercase = (value) => /[a-z]/.test(value)

export const containsNumber = (value) => /[0-9]/.test(value)

export const notOnlySpace = (value) => {
  if (!value) return true
  const valueTotest = value.trim()
  return Boolean(valueTotest.length)
}
