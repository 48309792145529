<template>
  <div class="mb-4 password-rules">
    <span class="d-block mb-2">Sua senha precisa utilizar:</span>

    <div class="grid-items">
      <span class="d-flex" :class="{ 'accepted-rule': !isMinLengthInvalid }">
        <mf-icon :color="isMinLengthInvalid ? 'cinza-0' : 'verde-0'" :icon="isMinLengthInvalid ? 'close' : 'check'" />
        Pelo menos 10 caracteres
      </span>

      <span class="d-flex justify-end" :class="{ 'accepted-rule': !isContainsUppercaseInvalid }">
        <mf-icon :color="isContainsUppercaseInvalid ? 'cinza-0' : 'verde-0'" :icon="isContainsUppercaseInvalid ? 'close' : 'check'" />
        Uma letra maiúscula
      </span>

      <span class="d-flex" :class="{ 'accepted-rule': !isContainsNumberInvalid }">
        <mf-icon :color="isContainsNumberInvalid ? 'cinza-0' : 'verde-0'" :icon="isContainsNumberInvalid ? 'close' : 'check'" />
        Um número
      </span>

      <span class="d-flex justify-end" :class="{ 'accepted-rule': !isContainsLowercaseInvalid }">
        <mf-icon :color="isContainsLowercaseInvalid ? 'cinza-0' : 'verde-0'" :icon="isContainsLowercaseInvalid ? 'close' : 'check'" />
        Uma letra minúscula
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordRules',
  components: {},
  props: {
    validations: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isMinLengthInvalid() {
      if (!this.validations?.newPassword?.$model) return true
      if (this.validations?.newPassword?.minLength?.$invalid) return true
      return false
    },
    isContainsUppercaseInvalid() {
      return this.validations?.newPassword?.containsUppercase?.$invalid
    },
    isContainsNumberInvalid() {
      return this.validations?.newPassword?.containsNumber?.$invalid
    },
    isContainsLowercaseInvalid() {
      return this.validations?.newPassword?.containsLowercase?.$invalid
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  row-gap: 8px;
}
.password-rules {
  span {
    font-size: 14px;
    color: $cinza-0;
  }

  .accepted-rule {
    color: $verde-0;
  }
}
</style>
